// 3rd-party Plugins
// @import "~highlight.js/styles/googlecode.css";
// @import "~perfect-scrollbar/css/perfect-scrollbar.css";

// Theme Styles
@import "./_metronic/_assets/sass/style.react.scss";

// Default Layout themes
// @import "./_metronic/_assets/sass/themes/layout/header/base/light.scss";
// @import "./_metronic/_assets/sass/themes/layout/header/menu/light.scss";
// @import "./_metronic/_assets/sass/themes/layout/brand/dark.scss";
// @import "./_metronic/_assets/sass/themes/layout/aside/dark.scss";

// Header themes
// Light
//@import "./_metronic/_assets/sass/themes/layout/header/base/light.scss";
// Dark
@import "./_metronic/_assets/sass/themes/layout/header/base/dark.scss";

// Header Menu themes
// Light
//@import "./_metronic/_assets/sass/themes/layout/header/menu/light.scss";
// Dark
// @import "./_metronic/_assets/sass/themes/layout/header/menu/dark.scss";

// Brand themes
.brand-dark {
  // @import "./_metronic/_assets/sass/themes/layout/brand/dark.scss";
}
// Light
// .brand-light {
//   @import "./_metronic/_assets/sass/themes/layout/brand/light.scss";
// }

// Aside themes
// Dark
@import "./_metronic/_assets/sass/themes/layout/aside/dark.scss";
// Light
// @import "./_metronic/_assets/sass/themes/layout/aside/light.scss";

// .example-highlight {
//   padding: 0rem 1.5rem 1.75rem !important;
// }

.example-highlight > pre {
  background: none transparent !important;
  margin: 0 !important;
  padding-top: 0.5rem; 
  code { 
      overflow-y: auto;
      display: block;

      span {
        background: none transparent !important;
      }
  }
}

.json > pre {
  background: none #fff !important;

}

.example-code > .json {
  background: none #fff !important;
}

.symbol.symbol-45 > svg {
  width: 100%;
  max-width: 45px;
  height: 45px;
}

.react-bootstrap-table {
  overflow-x: auto;
}

.react-bootstrap-table {
  th {
    outline: none;
   
    &.sortable {  
      .svg-icon-sort {
        opacity: 0;
      }
  
      &:hover {
        cursor: pointer;

        .svg-icon-sort {
          opacity: 1;
        }
      }
    }    
  }

  .table.table-head-custom thead tr {
    .sortable-active {
        color: $primary !important;
    }
  }
}



// .nav-link:focus {  
//     outline: none;  
// }

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

input::-ms-reveal,
input::-ms-clear {
  display: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.MuiIconButton-root {
  color: white !important;
}

.MuiCardContent-root:last-child {
  padding-bottom: 50px !important;
  padding-left: 50px;
  padding-right: 50px;
}

.MuiIconButton-label svg.MuiSvgIcon-root {
  fill: white;
}

#splash-screen img {
  margin-left: calc(121vw - 100%) !important;
}

.spinner {
  margin-left: calc(121vw - 100%) !important;
}

#addPanelId:hover {
  color: white;
}

.makeStyles-root-1 {
  overflow-x: hidden;
}
.MuiOutlinedInput-input {
  padding: 10.5px 14px !important;
}

.MuiPickersDay-day {
  color: rgba(0, 0, 0, 0.87) !important;
}

.dropdown-menu-xl {
  top: 59px !important;
  left: 24px !important;
}

.extended-alignment {
  transform: translate3d(-296px, -19px, 0px) !important;
}

.required-span {
  color: red;
}

.flag-select {
  border: 1px solid #ccc;
  margin: 5px 0;
  border-radius: 3.5px;
  padding: 7px;
  font-size: 13px !important;
  margin-bottom: 8px !important;
}

.flag-select .country-flag .country-label {
  font-size: 13px;
}

.country-flag img {
  top: 0px !important;
}

.flag-select .flag-options {
  width: 100%;
  margin-left: -8px;
}

.flag-select .arrow-down {
  float: right !important;
  padding: 0px !important;
}

.breadcrums {
  text-transform: capitalize !important;
  font-size: 15px;
}

.breadcrums:hover {
  color: blue !important;
}

.breadcrums-inactive {
  text-transform: capitalize !important;
  font-size: 15px;
  pointer-events: none;
}

.selected--flag--option {
  font-size: 14px !important;
}

.MuiOutlinedInput-input {
  padding-right: 10% !important;
}
